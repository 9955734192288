import { AxiosRequestConfig } from 'axios';
import { requestStu as client } from '@/request/index';
import { getBasicInfo } from '@wk/wk-gatherer';
import router from 'next/router';
import Cookie from 'js-cookie';
import { TRIGGER_EVENT } from '@/utils/constants';
import { setLastLoginType } from '@/utils/login';
import Regex from '@/utils/regex';
import {
  BindOtherWayReq,
  CountriesRes,
  EmailCodeLoginReq,
  EmailCodeLoginRes,
  LoginRes,
  SendEmailCodeReq,
  V2ForgetPwdReq,
  V2LoginReq,
  V2RegisterReq,
  V2SendCodeReq,
  V2VerifyCodeReq,
  WechatLoginReq,
  OthersLoginMethodRes,
  ContentData,
  StudentInfoRes,
  GetPictureRes,
  GetPictureReq,
  ReqCheckRes,
  ReqCheckReq,
  AppContent,
  SwitchStudentForGS2Req,
  LoginForLandingRes,
  RegisterByCodeReq,
  GetEmailAndPhoneByCodeRes,
} from './interface/login';
import { getTimestamp } from './common';

export const sendLoginEmailCode = (type: 'register' | 'login', data: SendEmailCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/auth/v2/email/send_code/login/${type}`, data)
    .then((res: any) => res.content);
};

export const sendEmailCode = (data: SendEmailCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/auth/v2/email/send_code`, data)
    .then((res: any) => res.content);
};

export const loginOrRegisterByEmail = (data: EmailCodeLoginReq, config?: AxiosRequestConfig): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/app/email_verification_code/login', data, config);
};

export const loginByEmail = (data: EmailCodeLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/app/email_verification_code/login/v2', data);
};

export const loginByPassword = (data: EmailCodeLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/email', data);
};

export const registerAccount = (data: EmailCodeLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/register', data);
};

export const forgetPassword = (data: Omit<EmailCodeLoginReq, 'email'>): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/recover_password', data);
};

export const getTokenByJsCode = (data: WechatLoginReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/auth/v2/login/wechat/applet', data);
};

export const getTokenByRefreshToken = (): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/api/authorized/refresh_token');
};

export const switchStudent = (studentId: string) => {
  return client.post<LoginRes, LoginRes>(`/api/acc/account/switch/${studentId}`);
};

export const switchStudentForGS2 = (data: SwitchStudentForGS2Req) => {
  return client.post<LoginRes, LoginRes>(`api/acc/gs_user/switch/${data.studentId}`, {
    teacherId: data?.teacherId,
    ...(data?.gsParams || {}),
    payload: JSON.stringify({
      ...getBasicInfo(),
      ...router.query,
      login_triggerevent: Cookie.get(TRIGGER_EVENT) || '',
    }),
  });
};

export const checkHasPwd = () => {
  return client
    .post<ContentData<boolean>, ContentData<boolean>>('/api/acc/account/already_set_password')
    .then((res: any) => res.content);
};

export const getCountryList = () => {
  return client
    .get<ContentData<CountriesRes>, ContentData<CountriesRes>>(`/public/open/countries/zh/ddl/local`)
    .then((res: any) => res.content);
};
// 适用于mobile端的数据结构
export const getCountryListMobile = () => {
  return client
    .get<ContentData<CountriesRes>, ContentData<CountriesRes>>(`/public/open/countries/zh/ddl/local/home_page`)
    .then((res: any) => res);
};

export const loginByPhone = (data: V2LoginReq) => {
  setLastLoginType(data.oauthType);
  return client.post<LoginRes, LoginRes>(`/public/authentication/login`, {
    ...data,
    payload: JSON.stringify({
      ...getBasicInfo(),
      ...router.query,
      login_triggerevent: Cookie.get(TRIGGER_EVENT) || '',
    }),
    Origin: 'OTHERS',
    privacyAgree: true,
    leadId: router.query?.l,
  });
};

export const loginForLanding = (data: V2LoginReq) => {
  return client.post<LoginForLandingRes, LoginForLandingRes>(`public/authentication/gs_user/login`, {
    ...data,
    payload: JSON.stringify({
      ...getBasicInfo(),
      ...router.query,
      login_triggerevent: Cookie.get(TRIGGER_EVENT) || '',
    }),
    Origin: 'OTHERS',
    privacyAgree: true,
    leadId: router.query?.l,
  });
};

export const checkForgetPwd = (type: 'EMAIL' | 'MOBILE', target: string) => {
  return client
    .post<any, any>(`/public/authentication/password_forgot`, { target, contactType: type })
    .then((res: any) => res.content);
};

export const forgetPasswordV2 = (data: V2ForgetPwdReq) => {
  return client.post<LoginRes, LoginRes>(`/public/authentication/recover_password`, data);
};

export const registerAccountV2 = (data: V2RegisterReq): Promise<LoginRes> => {
  return client.post<LoginRes, LoginRes>('/public/authentication/register', {
    ...data,
    target: data?.registerWay === 'EMAIL' ? data?.target?.trim?.() : data?.target?.replace?.(Regex.Dash, ''),
    payload: JSON.stringify({
      ...getBasicInfo(),
      ...router.query,
      login_triggerevent: Cookie.get(TRIGGER_EVENT) || '',
    }),
    privacyAgree: true,
  });
};

export const checkPhoneExist = (data: V2RegisterReq) => {
  return client
    .post<ContentData<boolean>, ContentData<boolean>>(`/public/authentication/exist_account`, data)
    .then((res: any) => res.content);
};

export const checkVerifyCode = (data: V2VerifyCodeReq) => {
  return client
    .post<ContentData<boolean>, ContentData<boolean>>(`/public/authentication/verify`, data)
    .then((res: any) => res.content);
};

export const bindOtherWay = (data: BindOtherWayReq) => {
  return client.post<LoginRes, LoginRes>(`/public/authentication/bind`, data);
};

export const bindConnectWay = (data: V2SendCodeReq) => {
  return client.post<LoginRes, LoginRes>(`/api/acc/account/change/email_phone`, data);
};

export const verifyPhone = (phone: string) => {
  return client.get<ContentData<boolean>, ContentData<boolean>>(`public/open/valid_phone/${phone}`);
};
/**
 * 获取三方登录方式
 */

export const getOthersLoginMethod = () => {
  return client
    .get<ContentData<OthersLoginMethodRes>, ContentData<OthersLoginMethodRes>>('/public/open/web/properties')
    .then((res: any) => res.content);
};

export const getStudentList = () => {
  return client.get<StudentInfoRes, StudentInfoRes>('api/acc/account/students').then((res) => res?.content);
};

export const getPicture = (data: GetPictureReq) => {
  return client.post<GetPictureRes, GetPictureRes>(`/captcha/get`, data).then((res) => res);
};

export const reqCheck = (data: ReqCheckReq) => {
  return client.post<ReqCheckRes, ReqCheckRes>(`/captcha/check`, data).then((res) => res);
};

export const getAccountInfo = () => {
  return client.get<LoginRes, LoginRes>('/api/acc/account').then((res) => res?.content);
};

// 获取app版本信息
export const getAppsSource = () => {
  return client.get<AppContent, AppContent>('/public/app/info/download').then((res) => res?.content);
};

/* ---------------------------------- 开始：原发送验证码接口 --------------------------------- */
export const sendBindCode = (data: V2SendCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/authentication/verify/send_code`, data)
    .then((res: any) => res.content);
};

export const sendVerifyCodeV2 = (data: V2SendCodeReq) => {
  return client
    .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/authentication/verify/send_code/authentication`, data)
    .then((res: any) => res.content);
};

export const sendVerifyCodeV3 = (api: string, data: V2SendCodeReq) => {
  return client.post<EmailCodeLoginRes, EmailCodeLoginRes>(`${api}`, data).then((res) => res.content);
};
/* ---------------------------------- 结束 --------------------------------- */

/* ---------------------------------- 开始：新发送验证码接口 --------------------------------- */
export const sendBindCodeNew = (data: V2SendCodeReq) => {
  return getTimestamp().then((res) => {
    return client
      .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/authentication/verify/web/send_code`, data, {
        signature: true,
        timestamp: res,
      })
      .then((res) => res.content);
  });
};

export const sendVerifyCodeV2New = (data: V2SendCodeReq) => {
  return getTimestamp().then((res) => {
    return client
      .post<EmailCodeLoginRes, EmailCodeLoginRes>(`/public/authentication/verify/web/send_code/authentication`, data, {
        signature: true,
        timestamp: res,
      })
      .then((res) => res.content);
  });
};

export const sendVerifyCodeV3New = (api: string, data: V2SendCodeReq) => {
  return getTimestamp().then((res) => {
    return client
      .post<EmailCodeLoginRes, EmailCodeLoginRes>(`${api}`, data, {
        signature: true,
        timestamp: res,
      })
      .then((res) => res.content);
  });
};
/* ---------------------------------- 结束 --------------------------------- */

/**
 * 通过code登录
 */
export const loginByCode = (code: string) => {
  return client.post<LoginRes, LoginRes>(`/public/authentication/code_login`, { code }).then((res) => res.content);
};

/**
 * 通过code注册
 */
export const registerByCode = (data: RegisterByCodeReq) => {
  return client.post<LoginRes, LoginRes>(`/public/authentication/code_register`, data).then((res) => res.content);
};

/**
 * 通过code获取邮箱与手机号
 */
export const getEmailAndPhoneByCode = (code: string) => {
  return client
    .get<GetEmailAndPhoneByCodeRes, GetEmailAndPhoneByCodeRes>(`/public/authentication/code_detail/${code}`)
    .then((res) => res?.content);
};
