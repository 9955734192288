import '@/styles/globals.css';
import '@/styles/themeColor.scss';
import '@/styles/kaamel.custom.scss';
// import localFont from 'next/font/local';
import { Figtree, Noto_Sans_SC } from 'next/font/google';
import React, { useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { IContextProps, MyContext } from '@/config/context-manager';
import classnames from 'classnames';
import { DefaultSeo } from 'next-seo';
import { init } from '@wk/wk-gatherer';
import { gtag, install } from 'ga-gtag';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { useTranslation, appWithTranslation } from 'next-i18next';

import type { AppProps } from 'next/app';
import useIsMobile from '@/hooks/useIsMobileNew';
import Script from 'next/script';
import { DISABLE_LAYOUT_PATHS, GTM_ARGUS, BASE_URL, getLoginReportL, DISABLE_KAAMEL_PATHS } from '@/utils/constants';
import { hideIntercomUrls } from '@/utils/utils';
import { mergeQueryParams } from '@/utils/url';
import { Providers } from '@/providers';
import useDeviceAdapter from '@/hooks/useDeviceAdapter';
import { reportCookieInfo } from '@/request/agreement';
import { ReportCookieInfoReq } from '@/request/interface/agreement';
import SEO from '../../configs/next-seo.config';

const GlobalLogin = dynamic(() => import('@/components/GlobalLogin'), { ssr: false });

// const sourceHanSans = localFont({
//   src: [
//     {
//       path: '../../public/font/source-han-sans-cn-woff2/SourceHanSansCN-Bold.woff2',
//       weight: '900',
//     },
//   ],
//   variable: '--font-sourceHanSans',
// });

const EXCLUDE_GLOBAL_LOGIN_PATHS = ['/student-center-login'];

const figtree = Figtree({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-figtree',
  weight: ['300', '400', '500', '600', '700', '800', '900'],
});

const sourceHanSans = Noto_Sans_SC({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-sourceHanSans',
  weight: ['900'],
});

const geSeoPath = (url: string) => {
  let seoPath = url.replace(/\/[^/]*$/, '/');
  seoPath = seoPath.replace('zh/', '');
  if (seoPath.includes('resources/detail/')) {
    seoPath = seoPath.replace('resources/detail/', 'resources/');
    seoPath = seoPath.replace(/\/$/, 'detail/');
  }

  return seoPath;
};

interface NextAppProps extends Omit<AppProps, 'Component'> {
  Component: AppProps['Component'] & {
    getLayout?(page: React.ReactNode): React.ReactElement;
  };
}

function App({ Component, pageProps }: NextAppProps) {
  const { i18n } = useTranslation(['common']);
  const isMobile = useIsMobile();
  const router = useRouter();
  const [initGTM, setInitGTM] = useState<boolean>(false);
  const [kaamelGAAgree, setKaamelGAAgree] = useState<boolean>(false);
  const [kaamelGTMAgree, setKaamelGTMAgree] = useState<boolean>(false);
  const [frameworkData, setFrameworkData] = useState<{ framework?: 'GDPR' | 'CCPA' | ''; finished?: boolean }>();
  // 移动端适配
  useDeviceAdapter();

  const { locale } = router;
  const value = useMemo(
    () => ({ locale, framework: frameworkData, kaamelGAAgree, kaamelGTMAgree }),
    [locale, frameworkData, kaamelGAAgree, kaamelGTMAgree],
  );
  const url = router.asPath;
  const isHideIntercom = hideIntercomUrls.some((item) => url?.toLowerCase()?.includes?.(item));
  const Layout = Component?.getLayout || ((page) => page);
  const seoPath = geSeoPath(url);

  // 停用 Google Analytics（分析）
  const disableGA = (disable: boolean) => {
    window[`ga-disable-${process.env.NEXT_PUBLIC_GA_ID}`] = disable;
    window[`ga-disable-${process.env.NEXT_PUBLIC_GA_ID_YOU_FIND}`] = disable;
    window[`ga-disable-${process.env.NEXT_PUBLIC_GA_ID_NEW}`] = disable;
    window[`ga-disable-${process.env.NEXT_PUBLIC_GA_ID_XLP}`] = disable;
  };

  const initGatherer = () => {
    init({
      env: (process.env.NEXT_PUBLIC_STAGE || 'test') as 'test' | 'prod',
      data: { ak: isMobile ? 'newofficial_mobile' : 'newofficial_website' },
    });
  };

  const initEruda = () => {
    // 加载eruda控制台 （ios会缓存提前页面资源， require并不会加载 换一种方式才加载eruda）
    if (isMobile && (process.env.NEXT_PUBLIC_STAGE === 'test' || /eruda=true/.test(window?.location?.href))) {
      (function () {
        const script = document.createElement('script');
        script.src = '//cdn.jsdelivr.net/npm/eruda';
        document.body.appendChild(script);
        script.onload = function () {
          window.eruda?.init();
        };
      })();
    }
  };

  const handleResourcesLeadId = () => {
    if (!window.sessionStorage.getItem('firstPath')) {
      window.sessionStorage.setItem('firstPath', router.asPath);
    }
  };

  useEffect(() => {
    // 初始化埋点
    initGatherer();
    // 初始化移动端devtool
    initEruda();
    // 处理学校园地l参逻辑
    handleResourcesLeadId();
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const newUrl = mergeQueryParams(url);
      if (newUrl !== url) {
        router.push(newUrl);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    const originalWindowOpen = window.open;
    window.open = (url, name, specs) => {
      const newUrl = mergeQueryParams(url as string);
      return originalWindowOpen(newUrl, name, specs);
    };
    getLoginReportL();

    const handleLinkClick = (event: Event) => {
      const target = (event.target as HTMLElement).closest('a');
      if (target && target.tagName === 'A' && target.href) {
        const newUrl = mergeQueryParams(target.href);
        const isNewWindow = target.target === '_blank';
        if (isNewWindow) {
          window.open(newUrl, '_blank');
          event.preventDefault();
        } else if (newUrl !== target.href) {
          event.preventDefault();
          router.push(newUrl);
        }
      }
    };
    document.addEventListener('click', handleLinkClick);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      window.open = originalWindowOpen;
      document.removeEventListener('click', handleLinkClick);
    };
  }, [router]);

  // 注册kaamel 服务方法
  useEffect(() => {
    // 打开ga
    const handleGAoPen = () => {
      disableGA(false);
      if (process.env.NEXT_PUBLIC_GA_ID_XLP) {
        install(process.env.NEXT_PUBLIC_GA_ID_XLP, { groups: 'group1' });
        // gtag('config', process.env.NEXT_PUBLIC_GA_ID_YOU_FIND, { groups: 'group1' });
        // gtag('config', process.env.NEXT_PUBLIC_GA_ID_NEW, { groups: 'group1' });
        gtag('config', process.env.NEXT_PUBLIC_GA_ID_XLP, { groups: 'group1' });
        gtag('config', process.env.NEXT_PUBLIC_GA_ID, { groups: 'group1' });
        setKaamelGAAgree(true);
      }
    };
    // 判断是否需要加载kaamel ga也不加载
    const showTip = DISABLE_LAYOUT_PATHS?.some((item) => {
      return router.asPath?.startsWith(item);
    });
    if (showTip) {
      return;
    }
    // 不加载kaamel 但是加载ga
    const disableKaamelOnGa = DISABLE_KAAMEL_PATHS?.some((item) => {
      return router.asPath?.startsWith(item);
    });
    if (disableKaamelOnGa) {
      handleGAoPen();
      return;
    }
    const script = document.createElement('script');
    script.src = `/kaamel.js?`;
    script.type = 'text/javascript';
    script.id = 'kaameljs';
    document.getElementsByTagName('head')[0].appendChild(script);
    // js 加载后再去判断
    script.onload = () => {
      if (typeof window !== 'undefined' && window.kaamelObj) {
        // 注册kaamel服务
        window.kaamelObj.servicesEffective(['analytics', 'doubleclick', 'googletagmanager']);
        // 判断功能是否启用获取具体适配法律框架
        window.kaamelSetting.onFrameworkLoaded = (frameworkName: 'GDPR' | 'CCPA' | '') => {
          setFrameworkData({
            finished: true,
            framework: frameworkName,
          });

          window.kaamel.parameters.horizontalBarFontFamily = '';
          window.kaamel.parameters.mainBarBtnMarginBottom = '0px';
          window.kaamel.parameters.mainBarDenyAllBtnMarginLeft = '0px';
          window.kaamel.parameters.mainBarAcceptAllBtnMarginLeft = '16px';
          window.kaamel.parameters.mainColor = '#cd292a';
          window.kaamel.parameters.horizontalBarFontFamily =
            locale === 'en'
              ? figtree.style.fontFamily
              : 'ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji';
        };
      }
    };

    if (typeof window !== 'undefined') {
      window.kaamelSetting = {
        googletagmanager: {
          init() {
            setInitGTM(true);
            setKaamelGTMAgree(true);
          },
          unload() {
            setInitGTM(false);
            setKaamelGTMAgree(false);
          },
        },
        analytics: {
          init() {
            handleGAoPen();
          },
          unload() {
            disableGA(true);
            setKaamelGAAgree(false);
          },
        },
        doubleclick: {
          init() {},
          unload() {},
        },
        dataCollection: (reportData: ReportCookieInfoReq) => {
          if (['new_close_right_banner', 'new_close_right_pannel']?.includes(reportData?.triggerId)) {
            return;
          }
          reportCookieInfo(reportData);
        },
      };
    }
  }, []);

  useEffect(() => {
    const checkIntercomAndInvoke = () => {
      if (window?.Intercom) {
        // Intercom 已初始化，调用其方法
        window?.Intercom?.('show');
        return true;
      }
      return false;
    };

    if (router.isReady && router.query.openIntercome) {
      const intervalId = setInterval(() => {
        if (checkIntercomAndInvoke()) {
          clearInterval(intervalId);
        }
      }, 100);

      return () => clearInterval(intervalId);
    }
    return undefined;
  }, [router]);

  return (
    <MyContext.Provider value={value as IContextProps}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
        />
        <meta name="applicable-device" content="pc,mobile" />
        <meta httpEquiv="Cache-Control" content="no-transform" />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <link
          rel="shortcut icon"
          href="/favicon.ico"
          // @ts-ignore
          mce_href="/favicon.ico"
          type="image/x-icon"
        />
        <meta data-n-head="ssr" data-hid="mobile-web-app-capable" name="mobile-web-app-capable" content="yes" />
        <link
          rel="alternate"
          href={`${BASE_URL}${i18n.language === 'zh' ? '/zh' : ''}${seoPath}`}
          // @ts-ignore
          hreflang={i18n.language}
        />
        <link
          rel="alternate"
          href={`${BASE_URL}${i18n.language === 'zh' ? '' : '/zh'}${seoPath}`}
          // @ts-ignore
          hreflang={i18n.language === 'zh' ? 'en' : 'zh'}
        />
        <link
          rel="alternate"
          href={`${BASE_URL}${i18n.language === 'zh' ? '/zh' : ''}${seoPath}`}
          // @ts-ignore
          hreflang="x-default"
        />
        <style
          dangerouslySetInnerHTML={{
            __html: `
            :root {
              --kaamel-cookie-title: ${locale === 'en' ? '"Customize Settings"' : '"个性化设置"'}
          }
        `,
          }}
        />
      </Head>
      <DefaultSeo
        {...SEO}
        norobots
        // 其他 SEO 配置
        additionalMetaTags={[
          {
            name: 'robots',
            // content: 'index, follow', // 在这里加入空格
            content: process.env.NEXT_PUBLIC_STAGE === 'prod' ? 'index, follow' : 'noindex, nofollow',
          },
        ]}
        // 标准链接 用router.asPath 为动态路径情况 才能拿到真实显示路径
        canonical={`${BASE_URL}${i18n.language === 'zh' ? '/zh' : ''}${seoPath}`}
      />
      <Script
        id="js-facebook-official"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', 1032673631108666);
            fbq('init', 906047510780866);
            fbq('track', 'PageView');
          `,
        }}
      />
      {!isHideIntercom && (
        <Script
          strategy="lazyOnload"
          id="intercom"
          dangerouslySetInnerHTML={{
            __html: `
           window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "tn0n4uq4",
      };
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/tn0n4uq4';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
         `,
          }}
        />
      )}
      <div className={classnames(figtree.variable, sourceHanSans.variable, locale === 'en' && 'font-figtree')}>
        <GTMProvider state={{ ...GTM_ARGUS, injectScript: initGTM }}>
          <Providers>
            <>
              <div>{Layout(<Component {...pageProps} />)}</div>
              {EXCLUDE_GLOBAL_LOGIN_PATHS?.includes(router.pathname) ? null : <GlobalLogin />}
              {/* {!isHideIntercom && <FloatPhone />} */}
            </>
          </Providers>
        </GTMProvider>
      </div>
    </MyContext.Provider>
  );
}

export default appWithTranslation(App);
